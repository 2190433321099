<template>
  <footer class="fixed bottom-0 shadow-inner w-full bg-white"
          :class="width?width:'' , customClasses?customClasses:''"
  >
    <div class="text-gray-900 flex gap-1 flex-row justify-between pt-2 px-2 md:px-6 items-center">
      <div class="flex flex-row gap-2 items-center">
        <div class="flex flex-row justify-center items-center text-xs gap-2">
          <p class="hidden md:inline">{{ ('Powered by') }}</p>
          <img class="h-5 w-auto" src="~/assets/icon/app.png" alt=""/>
          <p>Aphelia.io</p>
        </div>
      </div>

      <div class="flex flex-row  items-center justify-center  text-xs gap-2">
        <span class="text-gray-500  text-xs">{{ today }}. All rights reserved.</span>
        <div class="mb-[14px] ">
          <a href="https://technuf.com/" target="_blank"><img class="h-6 w-auto  mr-1" src="~/assets/icon/logo-dark.svg"
                                                              alt=""/></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import moment from "moment";

let today = moment().format('YYYY')

let {width, customClasses} = defineProps(['width', 'customClasses'])
</script>

<style scoped>

</style>